<template>
    <v-dialog fullscreen v-model="metricsDialog">
        <template v-slot:activator="{ on: dialog }">
            <v-list-item
                v-on="{ ...dialog }"
                v-if="$route.params.sid"
                :two-line="screenSize === 'large' || screenSize === 'small'"
                :three-line="screenSize === 'medium'"
            >
                <v-list-item-avatar>
                    <v-icon large>analytics</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        <h4 class="mr-2 primary--text">Usage</h4>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span class="caption mr-3">Monitor usage and statistics</span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-tooltip v-else right>
                <template v-slot:activator="{ on: tooltip }">
                    <v-list-item v-on="{ ...tooltip, ...dialog }">
                        <v-list-item-icon>
                            <v-icon>analytics</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            Metrics
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <span>Usage</span>
            </v-tooltip>
        </template>
        <v-card flat>
            <v-card-title>
                <div style="width:100%" class="d-flex justify-space-between align-center title primary--text">
                    <div class="d-flex align-center text-uppercase">
                        <v-icon class="mr-1">analytics</v-icon>
                        usage
                    </div>
                    <div class="d-flex align-center">
                        <span class="mr-3 text-uppercase">{{ $store.getters['spaceStore/spaceLongNameById']($route.params.sid) }}</span>
                        <v-divider v-if="$route.params.sid" class="mr-1" vertical></v-divider>
                        <v-btn icon @click="metricsDialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-tabs class="mt-10">
                    <v-tab>applications</v-tab>
                    <v-tab-item
                        ><ReportingDashboardData
                            :dialogStatus="metricsDialog"
                            :metric="metrics.APP_USAGE"
                            :spaceNames="spaceNames"
                            :userNames="userNames"
                        ></ReportingDashboardData>
                    </v-tab-item>
                    <v-tab>hpc</v-tab>
                    <v-tab-item
                        ><ReportingDashboardData
                            :dialogStatus="metricsDialog"
                            :metric="metrics.HPC_USAGE"
                            :spaceNames="spaceNames"
                            :userNames="userNames"
                        ></ReportingDashboardData>
                    </v-tab-item>
                    <v-tab>users</v-tab>
                    <v-tab-item
                        ><ReportingDashboardData
                            :dialogStatus="metricsDialog"
                            :metric="metrics.USERS_STATISTICS"
                            :spaceNames="spaceNames"
                            :userNames="userNames"
                        ></ReportingDashboardData>
                    </v-tab-item>
                    <v-tab>invitations</v-tab>
                    <v-tab-item
                        ><ReportingDashboardData
                            :metric="metrics.INVITATION_STATISTICS"
                            :spaceNames="spaceNames"
                            :userNames="userNames"
                            :dialogStatus="metricsDialog"
                        ></ReportingDashboardData>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { sortArray } from '@/utils'

const ReportingDashboardData = () => import('@/components/ReportingDashboardData')

export default {
    components: { ReportingDashboardData },
    name: 'MonitoringDashboard',
    props: { screenSize: String },
    data() {
        return {
            metricsDialog: false,
            metrics: {
                APP_USAGE: 'apps',
                HPC_USAGE: 'hpc',
                USERS_STATISTICS: 'users',
                INVITATION_STATISTICS: 'invitations'
            }
        }
    },
    computed: {
        ...mapState(['userOrgs']),
        ...mapState('orgStore', ['spacesWithPrimarySnapshots', 'orgUsers']),
        ...mapState('spaceStore', ['spaceUsers']),
        spaceNames() {
            if (this.spacesWithPrimarySnapshots) {
                const sortedSpaces = sortArray(this.spacesWithPrimarySnapshots, 'space_long_id', 'ascending', true)
                const spaceNames = ['All spaces'].concat(sortedSpaces.map(space => space.space_long_id))
                return spaceNames
            }
            return []
        },
        userNames() {
            if (this.$route.params.sid && this.spaceUsers) {
                const sortedUsers = sortArray(this.spaceUsers, 'full_name', 'ascending', true)
                const userNames = ['All users'].concat(sortedUsers.map(user => user.full_name))
                return userNames
            } else if (this.$route.params.oid && this.orgUsers) {
                const sortedUsers = sortArray(this.orgUsers, 'full_name', 'ascending', true)
                const userNames = ['All users'].concat(sortedUsers.map(user => user.full_name))
                return userNames
            }
            return []
        }
    },
    mounted() {
        if (this.$route.params.oid && !this.$route.params.sid) {
            this.$store.dispatch('orgStore/fetchOrgUsers', this.$route.params.oid)
        } else if (this.$route.params.sid) {
            this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
        }
    }
}
</script>
