var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var dialog = ref.on
            return [
              _vm.$route.params.sid
                ? _c(
                    "v-list-item",
                    _vm._g(
                      {
                        attrs: {
                          "two-line":
                            _vm.screenSize === "large" ||
                            _vm.screenSize === "small",
                          "three-line": _vm.screenSize === "medium"
                        }
                      },
                      Object.assign({}, dialog)
                    ),
                    [
                      _c(
                        "v-list-item-avatar",
                        [
                          _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v("analytics")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _c("h4", { staticClass: "mr-2 primary--text" }, [
                              _vm._v("Usage")
                            ])
                          ]),
                          _c("v-list-item-subtitle", [
                            _c("span", { staticClass: "caption mr-3" }, [
                              _vm._v("Monitor usage and statistics")
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var tooltip = ref.on
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    {},
                                    Object.assign({}, tooltip, dialog)
                                  ),
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [_c("v-icon", [_vm._v("analytics")])],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(" Metrics ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v("Usage")])]
                  )
            ]
          }
        }
      ]),
      model: {
        value: _vm.metricsDialog,
        callback: function($$v) {
          _vm.metricsDialog = $$v
        },
        expression: "metricsDialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-space-between align-center title primary--text",
                staticStyle: { width: "100%" }
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center text-uppercase" },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [
                      _vm._v("analytics")
                    ]),
                    _vm._v(" usage ")
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("span", { staticClass: "mr-3 text-uppercase" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$store.getters["spaceStore/spaceLongNameById"](
                            _vm.$route.params.sid
                          )
                        )
                      )
                    ]),
                    _vm.$route.params.sid
                      ? _c("v-divider", {
                          staticClass: "mr-1",
                          attrs: { vertical: "" }
                        })
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            _vm.metricsDialog = false
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs",
                { staticClass: "mt-10" },
                [
                  _c("v-tab", [_vm._v("applications")]),
                  _c(
                    "v-tab-item",
                    [
                      _c("ReportingDashboardData", {
                        attrs: {
                          dialogStatus: _vm.metricsDialog,
                          metric: _vm.metrics.APP_USAGE,
                          spaceNames: _vm.spaceNames,
                          userNames: _vm.userNames
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-tab", [_vm._v("hpc")]),
                  _c(
                    "v-tab-item",
                    [
                      _c("ReportingDashboardData", {
                        attrs: {
                          dialogStatus: _vm.metricsDialog,
                          metric: _vm.metrics.HPC_USAGE,
                          spaceNames: _vm.spaceNames,
                          userNames: _vm.userNames
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-tab", [_vm._v("users")]),
                  _c(
                    "v-tab-item",
                    [
                      _c("ReportingDashboardData", {
                        attrs: {
                          dialogStatus: _vm.metricsDialog,
                          metric: _vm.metrics.USERS_STATISTICS,
                          spaceNames: _vm.spaceNames,
                          userNames: _vm.userNames
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-tab", [_vm._v("invitations")]),
                  _c(
                    "v-tab-item",
                    [
                      _c("ReportingDashboardData", {
                        attrs: {
                          metric: _vm.metrics.INVITATION_STATISTICS,
                          spaceNames: _vm.spaceNames,
                          userNames: _vm.userNames,
                          dialogStatus: _vm.metricsDialog
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }